import { useEffect, useState } from 'react';
import { useGeolocation } from '~/hooks';
import { DEFAULT_COUNTRY_CODE } from '~/utils';
import { useGrimmeDataPerRegion } from './provider';
import { GrimmeContactsPerRegion } from './types';

export function useContactsPerRegion() {
  const [contactsPerRegion, setContactsPerRegion] =
    useState<GrimmeContactsPerRegion>(null);
  const dataPerRegionData = useGrimmeDataPerRegion();
  const { countryCode } = useGeolocation();

  useEffect(() => {
    const contactsListPerRegion =
      dataPerRegionData?.contactsRaw?.grimme_contacts_per_region;
    if (contactsListPerRegion) {
      const contactsPerRegionToSet =
        contactsListPerRegion.find(
          (contactList) => contactList.code === countryCode,
        ) ??
        contactsListPerRegion.find(
          (contactList) => contactList.code === DEFAULT_COUNTRY_CODE,
        );
      setContactsPerRegion(contactsPerRegionToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return contactsPerRegion;
}
