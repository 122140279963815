import { styled } from '@mui/material';
import Image, { ImageLoader, ImageProps } from 'next/image';

const StyledImage = styled(Image)(() => ({
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center !important',
}));

export const butterImageLoader: ImageLoader = ({ src, width, quality }) => {
  const butterSrc = src.split('/').pop();

  return `https://cdn.buttercms.com/resize=width:${width}/output=format:webp,quality:${
    quality || 75
  }/${butterSrc}`;
};

// Generates a placeholder image by requesting a very low quality image from ButterCMS.
export const placeholderImageUrl = ({
  src,
  placeholderWidth = 20,
}: {
  src: string;
  placeholderWidth?: number;
}) => {
  const butterSrc = src.split('/').pop();

  return `https://cdn.buttercms.com/resize=width:${placeholderWidth}/output=format:webp,quality:1/${butterSrc}`;
};

// TODO: Fix passing of showPlaceholder, placeholderWidth to Image component
export function ButterImage(
  props: Omit<ImageProps, 'loader' | 'placeholder' | 'blurDataURL'> & {
    showPlaceholder?: boolean;
    placeholderWidth?: number;
  },
) {
  return (
    <StyledImage
      alt={props.alt}
      {...props}
      loader={butterImageLoader}
      placeholder={props.showPlaceholder ?? false ? 'blur' : 'empty'}
      blurDataURL={
        props.showPlaceholder ?? true
          ? placeholderImageUrl({
              src: props.src as string,
              placeholderWidth: props.placeholderWidth,
            })
          : undefined
      }
    />
  );
}
