import { Box, generateUtilityClasses } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('LandingHero', [
  'butterImage',
  'discoverMoreBoxButton',
  'discoverMoreStack',
  'gridItem',
  'mainBox',
  'mainGrid',
  'stack',
  'subtitle',
]);

export const RootBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  minHeight: '100vh',
  position: 'relative',
  [`& .${classes.butterImage}`]: {
    objectFit: 'cover',
  },
  [`& .${classes.gridItem}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  [`& .${classes.mainGrid}`]: {
    alignItems: 'center',
    minHeight: '105vh',
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(7),
  },
  [`& .${classes.discoverMoreStack}`]: {
    alignItems: 'center',
    animation: `${floating} 3s ease-in-out infinite`,
    bottom: 0,
    display: 'none',
    left: 0,
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  [`& .${classes.discoverMoreBoxButton}`]: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  [`& .${classes.mainBox}`]: {
    position: 'relative',
  },
  [`& .${classes.stack}`]: {
    alignItems: 'stretch',
    alignContent: 'center',
  },
  [`& .${classes.subtitle}`]: {
    paddingTop: theme.spacing(2),
  },
}));

const floating = keyframes`
  0% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(0px);
  }
`;
