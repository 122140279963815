import { createContext, useContext } from 'react';
import { GrimmeDataPerRegionsProps } from './types';

const GrimmeDataPerRegionContext =
  createContext<GrimmeDataPerRegionsProps>(null);

export function GrimmeDataPerRegionProvider(props) {
  const { children, value } = props;
  return (
    <GrimmeDataPerRegionContext.Provider value={value}>
      {children}
    </GrimmeDataPerRegionContext.Provider>
  );
}

export function useGrimmeDataPerRegion() {
  return useContext(GrimmeDataPerRegionContext);
}
