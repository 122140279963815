import { TitleDivider } from '@grimme/components';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LandingPageData } from '~/lib';
import { LocationSearchForm } from '../location-search-form';
import { PartnerCard } from './partner-card';
import { classes, StyledBox } from './styles';

export const PartnersSection = (props: LandingPageData['grimme_partners']) => {
  const {
    button,
    description,
    label,
    partner_button: partnerButton,
    search_label: searchLabel,
    search_description: searchDescription,
    title,
    countriesList,
  } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <StyledBox component="section">
      <Container>
        <Grid container>
          <Grid item md={6.5} xs={12} className={classes.textSectionWrapper}>
            <Box className={classes.textSection}>
              <Typography variant="h6">{label}</Typography>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="subtitle1" className={classes.description}>
                {description}
              </Typography>
              <TitleDivider />
              <Typography variant="h6" className={classes.searchLabel}>
                {searchLabel}
              </Typography>
              <Typography className={classes.searchDescription} variant="h5">
                {searchDescription}
              </Typography>
              <LocationSearchForm
                countriesList={countriesList}
                countrySelectLabel={t(
                  'myGRIMME_website_partners_country',
                  'Country',
                )}
                submitLabel={button}
                zipCodeTextfieldLabel={t(
                  'myGRIMME_website_partners_zip_code',
                  'Zip Code',
                )}
                action={`${language}/partners/`}
              />
            </Box>
          </Grid>
          <Grid item md={4.5} xs={12}>
            <PartnerCard partnerButton={partnerButton} />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};
