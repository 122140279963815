import { Box } from '@mui/material';
import { classes, Root } from './styles';

export const DarkenedComponent = ({ children }) => {
  return (
    <Root>
      {children}
      <Box className={classes.overlay} />
    </Root>
  );
};
