import { generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('DarkenedImage', [
  'bgImage',
  'overlay',
]);

export const Root = styled('div')({
  ['&']: {
    position: 'absolute',
    inset: 0,
  },
  [`& .${classes.overlay}`]: {
    position: 'absolute',
    inset: 0,
    background:
      'transparent linear-gradient(90deg, #000000 0%, #80808000 120%) 0% 0% no-repeat padding-box',
    opacity: 0.9,
  },
});
