import { Box } from '@mui/material';
import { SyntheticEvent } from 'react';

export function VideoComponent({ url }: { url: string }) {
  return (
    <Box
      component="video"
      preload="metadata"
      playsInline
      position="absolute"
      width="100%"
      height="100%"
      loop
      muted
      autoPlay
      disablePictureInPicture
      ref={(ref: HTMLVideoElement) => {
        // If the browsers cache is faster than react, it could be the case that
        // the event handler for onCanPlayThrough is not registered yet.
        // To handle this case, we check the readyState of the video element
        // and set the opacity to 1 if it is ready.
        if (ref?.readyState > 3) {
          ref.style.opacity = '1';
        }
      }}
      onCanPlayThrough={(e: SyntheticEvent<HTMLVideoElement, Event>) => {
        // This event is fired when the browser has buffered the video and can
        // play through to the end without any buffering.
        // When thats the case, we show the video.
        e.currentTarget.style.opacity = '1';
      }}
      sx={{ objectFit: 'cover', transition: '1s', opacity: 0 }}
    >
      <source src={url} type="video/mp4" />
    </Box>
  );
}
