import { IconLookup, library } from '@fortawesome/fontawesome-svg-core';
import { CtasSection, SeoSection, SpacedSection } from '@grimme/components';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { NextSeo } from 'next-seo';
import { ContactSection } from '~/components/contact-section';
import { DigitalSection } from '~/components/digital-section';
import { GrimmeDataPerRegionProvider } from '~/components/grimme-data-per-region/provider';
import { Hero } from '~/components/hero';
import { Layout } from '~/components/layout';
import { NewsSection } from '~/components/news-section';
import { PartnersSection } from '~/components/partners-section';
import { ProductDetailsPreview } from '~/components/product-details-preview';
import {
  LandingPageData,
  PartnersPageData,
  getContent,
  getPage,
  getProducts,
  listBlogPosts,
} from '~/lib';
import { withDefaultStaticProps } from '~/lib/composable';
import {
  DEFAULT_COUNTRY_CODE,
  NEWS_CATEGORY,
  parseCountriesFromContinents,
  parseCtaSectionButtons,
} from '~/utils';

export const getStaticProps = async ({
  locale,
  preview = false,
}: GetStaticPropsContext) => {
  return await withDefaultStaticProps({
    revalidate: 300,
    revalidateOnError: 10,
    locale,
    preview,
    props: async ({ includeIcons }) => {
      const landingPageData = await getPage<LandingPageData>(
        locale,
        preview,
        'grimme-landing-page',
      );
      const ladingHeroIconsToInclude =
        landingPageData?.fields?.hero?.links?.map((icon) => ({
          prefix: icon.icon_type,
          iconName: icon.icon,
        })) as IconLookup[];
      includeIcons(ladingHeroIconsToInclude);

      return {
        preview,
        landingPageData,
        newsFeed: await listBlogPosts({
          locale,
          preview,
          pageNumber: 1,
          pageSize: 3,
          categorySlug: NEWS_CATEGORY,
        }),
        productsDetails: await Promise.all(
          landingPageData.fields.product_details_page_preview.categories.map(
            async (category) => ({
              products: await getProducts(locale, category.slugs.split(',')),
              category: category.name,
            }),
          ),
        ),
        partnersData: await getPage<PartnersPageData>(
          locale,
          preview,
          'partners',
        ),
        /* 
        IMPORTANT: Calling regions based contacts ways only from DE locale
        because it will be our single source of truth for this kind of data.
        */
        contactsPerRegionData: await getContent(
          DEFAULT_COUNTRY_CODE.toLowerCase(),
          false,
          ['grimme_contacts_per_region'],
        ),
      };
    },
  });
};

export default function IndexPage(
  props: InferGetStaticPropsType<typeof getStaticProps>,
) {
  const {
    contactsPerRegionData,
    globalSettings,
    icons,
    landingPageData,
    newsFeed,
    partnersData,
    preview,
    productsDetails,
  } = props;
  library.add(...icons);

  return (
    <GrimmeDataPerRegionProvider value={{ contactsRaw: contactsPerRegionData }}>
      <Layout globalSettings={globalSettings} preview={preview}>
        <NextSeo
          description={landingPageData.fields.seo.description}
          nofollow={landingPageData.fields.seo.no_index}
          noindex={landingPageData.fields.seo.no_index}
          title={landingPageData.fields.seo.title}
        />
        <Hero {...landingPageData.fields.hero} />

        <SpacedSection px="small">
          <SeoSection
            {...landingPageData.fields.seo_section_components_library}
            sx={{ mt: 12, mb: 12 }}
          />
        </SpacedSection>

        <SpacedSection px="small">
          <ProductDetailsPreview
            productsDetails={productsDetails}
            {...landingPageData.fields.product_details_page_preview}
          />
        </SpacedSection>

        <SpacedSection px="large" bgcolor="white">
          <DigitalSection {...landingPageData.fields.grimme_digital} />
        </SpacedSection>

        <CtasSection
          title={landingPageData.fields.ctas_section.title}
          description={landingPageData.fields.ctas_section.description}
          backgroundImageUrl={
            landingPageData.fields.ctas_section.background_image
          }
          buttons={parseCtaSectionButtons(
            (landingPageData.fields.ctas_section &&
              landingPageData.fields.ctas_section.buttons) ||
              [],
          )}
        />

        <SpacedSection px="large">
          <NewsSection {...landingPageData.fields.news} feed={newsFeed.data} />
        </SpacedSection>

        <SpacedSection px="large">
          <PartnersSection
            {...landingPageData.fields.grimme_partners}
            countriesList={parseCountriesFromContinents(
              partnersData.fields.partners,
            )}
          />
        </SpacedSection>

        <CtasSection
          title={landingPageData.fields.grimme_group.title}
          description={landingPageData.fields.grimme_group.description}
          backgroundImageUrl={
            landingPageData.fields.grimme_group.background_image
          }
          buttons={parseCtaSectionButtons(
            (landingPageData.fields.grimme_group &&
              landingPageData.fields.grimme_group.buttons) ||
              [],
          )}
        />

        <SpacedSection px="large">
          <ContactSection {...landingPageData.fields.contact_section} />
        </SpacedSection>
      </Layout>
    </GrimmeDataPerRegionProvider>
  );
}
