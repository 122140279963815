import { Button, TextField } from '@grimme/components';
import { generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('LocationSearchForm', [
  'arrowIcon',
  'countrySelect',
]);

export const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  padding: theme.spacing(3, 0),
  [theme.breakpoints.up('sm')]: {
    flexWrap: 'nowrap',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 0),
  },
  [`& .${classes.countrySelect}`]: {
    maxWidth: 74,
    [`& .MuiSelect-select span`]: {
      display: 'none',
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  flex: 1,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  flex: '1 100%',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('sm')]: {
    flex: '0 auto',
  },
  [`& .${classes.arrowIcon}`]: {
    transform: 'rotate(90deg)',
  },
}));
